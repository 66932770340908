import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import Element from 'element-ui'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css
import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
import {columnUserFunction} from  './utils/tableColumn' // error log
import * as filters from './filters' // global filters
// icon选则组件
import eIconPicker from 'e-icon-picker'
import 'e-icon-picker/lib/symbol.js' // 基本彩色图标库
import 'e-icon-picker/lib/index.css' // 基本样式，包含基本图标
import 'font-awesome/css/font-awesome.min.css' // font-awesome 图标库
import 'element-ui/lib/theme-chalk/icon.css' // element-ui 图标库
import './directive/permission/index'

// Ueditor富文本编辑器
import '../public/static/Ueditor/UE/ueditor.config.js'
import '../public/static/Ueditor/UE/ueditor.all.min.js'
import '../public/static/Ueditor/UE/lang/zh-cn/zh-cn.js'
import '../public/static/Ueditor/UE/ueditor.parse.min.js'
import injectComps from '@/views/components.js';



console.log(injectComps);
injectComps.forEach(ele => {
    Vue.component(ele.name, ele);
});

Vue.use(Element, {
  size: Cookies.get('size') || 'mini' // set element-ui default size
});

Vue.prototype.$cookie = Cookies

Vue.prototype.$columnUserFunction = columnUserFunction

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

// 全局删除增加图标
Vue.use(eIconPicker, {
  FontAwesome: false,
  ElementUI: true,
  eIcon: false, // 自带的图标，来自阿里妈妈
  eIconSymbol: false, // 是否开启彩色图标
  addIconList: [],
  removeIconList: [],
  zIndex: 3100// 选择器弹层的最低层,全局配置
})

// 防止el-button重复点击
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 2000)
      }
    })
  }
});

//下载文件。不是浏览器打开
Vue.directive('down', {
  inserted: (el, binding) => {
    el.addEventListener('click', () => {
      let link = document.createElement('a')
      let url = binding.value
      // 这里是将url转成blob地址，
      fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        link.href = URL.createObjectURL(blob)
        console.log(link.href)
        link.download = ''
        document.body.appendChild(link)
        link.click()
      })
    })
  }
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

